//  Variations to Settings (inancientpaths)
//	----------------------
//
//  Table of Contents:
//
//	1. Color and palette variables
//	2. Top menu
//	3. Accordion
//	4. Tabs
//	5. Cards
//	6. Other bizazz settings
//	7. Overrides of foundation settings
//______________________________________

//	1. Color and palette variables
// -----------------------------------------------------------------------1

$colours: (
	colour1: (
		name: green,
		color: #006B05,
		background-color: #fff,
		lighterColour: scale-color(#006B05, $lightness: +25%),
		veryLightColour: scale-color(#006B05, $lightness: 80%),
		contrastColour: color-pick-contrast(#006B05),
	),
	colour2: (
		name: gold,
		color: #B4A804,
		background-color: #fff,
		lighterColour: scale-color(#B4A804, $lightness: +25%),
		veryLightColour: scale-color(#B4A804, $lightness: 80%),
		contrastColour: color-pick-contrast(#B4A804),
	),
	red: (
		name: red,
		color: #f00,
		background-color: #fff,
		lighterColour: scale-color(#f00, $lightness: +25%),
		veryLightColour: scale-color(#f00, $lightness: 80%),
		contrastColour: color-pick-contrast(#f00),
		textOnly: true,
	),
	highlight: (
		name: highlight,
		color: $black,
		background-color: #ff0,
		lighterColour: #ff0,
		veryLightColour: #ff0,
		contrastColour: #ff0,
		textOnly: true,
	),
	primary: (
		name: primary,
		color: $primary-color,
		background-color: #fff,
		lighterColour: scale-color($primary-color, $lightness: +25%),
		veryLightColour: scale-color($primary-color, $lightness: 80%),
		contrastColour: color-pick-contrast($primary-color),
		foundation: true,
	),
	secondary: (
		name: secondary,
		color: $secondary-color,
		background-color: #fff,
		lighterColour: scale-color($secondary-color, $lightness: +25%),
		veryLightColour: scale-color($secondary-color, $lightness: 80%),
		contrastColour: color-pick-contrast($secondary-color),
		foundation: true,
	),
	success: (
		name: success,
		color: $success-color,
		background-color: #fff,
		lighterColour: scale-color($success-color, $lightness: +25%),
		veryLightColour: scale-color($success-color, $lightness: 80%),
		contrastColour: color-pick-contrast($success-color),
		foundation: true,
	),
	alert: (
		name: alert,
		color: $alert-color,
		background-color: #fff,
		lighterColour: scale-color($alert-color, $lightness: +25%),
		veryLightColour: scale-color($alert-color, $lightness: 80%),
		contrastColour: color-pick-contrast($alert-color),
		foundation: true,
	),
	warning: (
		name: warning,
		color: $warning-color,
		background-color: #fff,
		lighterColour: scale-color($warning-color, $lightness: +25%),
		veryLightColour: scale-color($warning-color, $lightness: 80%),
		contrastColour: color-pick-contrast($warning-color),
		foundation: true,
	)
);

// Containers are used only for sites where only part of the content is constrained by grid-container.
// If any containers are defined here then the editor will have the grid-container style added too.
//$containers: (
//	container1: (
//		name: brown,
//		color: #f3e0b5,
//		background-color: #26150B,
//	),
//	container2: (
//		name: lightcream,
//		color: #000,
//		background-color: #f7edd9,
//	),
//	container3: (
//		name: orange,
//		color: #000,
//		background-color: #dd8c4b,
//	)
//);

@function getColour($scheme, $colour) {
	@return map-get(map-get($colours, $scheme), $colour);
}
// example use for above function; getColour(colour1, name);
// this 'gets' the colour1 name value, which equals aqua

//	2. Top menu
// -----------------------------------------------------------------------1

$titlebar-background: $white;
$titlebar-color: $black;
$titlebar-padding: 0.3rem 0 0.3rem 0;
//$titlebar-text-font-weight: bold;
$titlebar-icon-color: $black;
//$titlebar-icon-color-hover: $medium-gray;
$titlebar-icon-spacing: 0;

// Top menu colours and padding
//$topmenu-background: #26150b;
////$topmenu-text: getColour(colour1, color);
//$topmenu-text: #f3e0b5;
//$topmenu-current-text: getColour(colour2, color);
//$topmenu-hover: #ac3414;
////$topmenu-white: #fff;
////$topmenu-background-current: $topmenu-background;
////$topmenu-colour-current: getColour(colour1, color);

//$topmenu-title-padding: 0;
//$topmenu-topbar-padding: 0.7rem 1rem;
//$topmenu-text-decoration: none;

// Titlebar settings
//$titlebar-title-color: $topmenu-text;
//$titlebar-title-hover: $topmenu-text;
//$titlebar-background: $topmenu-background; // override foundation settings
//$titlebar-icon-color: $topmenu-text; // override foundation settings
//$titlebar-icon-color-hover: $topmenu-text; // override foundation settings
////$titlebar-padding: 0.5rem; // override foundation settings
//$titlebar-padding: 0;
// Topbar settings
//$topbar-title-color: $topmenu-text;
//$topbar-title-hover: $topmenu-text;
//$topbar-colour: $topmenu-text;
//$topbar-background: $topmenu-background; // override foundation settings
//$topbar-colour-hover: $topmenu-text;
//$topbar-background-hover: $topmenu-hover;
//$topbar-colour-current: $topmenu-current-text;
//$topbar-background-current: $topmenu-background;
//$topbar-submenu-colour: $topmenu-text;
//$topbar-submenu-background: $topmenu-background; // override foundation settings
//$topbar-submenu-colour-hover: $topmenu-text;
//$topbar-submenu-background-hover: $topmenu-hover;
//$topbar-submenu-current-colour: $topmenu-current-text;
//$topbar-submenu-current-background: $topmenu-background;
//$topbar-padding: 0; // override foundation settings
//$drilldown-colour: $topmenu-text;
//$drilldown-background: $topmenu-background;
//$drilldown-colour-hover: $topmenu-text;
//$drilldown-background-hover: $topmenu-hover;
//$drilldown-current-colour: $topmenu-current-text;
//$drilldown-current-background: $topmenu-background;
//$dropdownmenu-padding: $topmenu-topbar-padding; // override foundation settings

//	3. Accordion
// -----------------------------------------------------------------------1

$accordionColorDefault: $primary-color;
$accordionBackgroundDefault: $body-background;
$accordionHoverBackgroundDefault: $light-gray;
$accordionRadius: $global-radius;
$accordionRadiusOption: 0.6rem;
$accordionShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//	4. Tabs
// -----------------------------------------------------------------------1

$tabsColorDefault: color-pick-contrast($primary-color);
$tabsBackgroundDefault: $primary-color;
$tabsHoverBackgroundDefault: $light-gray;
$tabsRadius: $global-radius;
$tabsRadiusOption: 0.6rem;
$tabsShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//  5. Cards
// -----------------------------------------------------------------------5

$cardRadius: $global-radius;
$cardRadiusOption: 0.6rem;
$cardShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//	6. Other bizazz settings
// -----------------------------------------------------------------------1
$sitehighlight-color: #ff0;

//	7. Overrides of foundation settings
// -----------------------------------------------------------------------3

//	Buttons
//$button-background: getColour(colour2, color);
//$button-background-hover: scale-color($button-background, $lightness: -15%);
////$button-color: $white;
//$button-radius: 5px;
////$button-padding: 1rem 2rem;

// Link color
////$anchor-color: $primary-color;
//$anchor-color: getColour(colour2, color);
//$anchor-color-hover: scale-color($anchor-color, $lightness: 14%);
////$anchor-text-decoration: underline;
////$anchor-text-decoration-hover: underline;

// Lists
//$list-side-margin: 1.25rem; // set ol, ul margin-left
$list-side-margin: 2.25rem;
//$list-nested-side-margin: 1.25rem;
$list-nested-side-margin: 2.25rem;
$list-side-margin-small: 1.25rem; // set ol, ul margin-left on small screen
$list-nested-side-margin-small: 1.25rem;

// Forms
$form-label-font-size: rem-calc(16);
$input-font-size: rem-calc(16);

// Other
//$button-background-hover: scale-color($button-background, $lightness: +32%);
//$button-background-hover-lightness: -25%;
$global-radius: 0.6rem;
// off canvas width
//$offcanvas-sizes: (
//  small: 300px,
//  medium: 400px,
//);

