/* Webicons by Adam Fairhead: http://adamfairhead.com */

.webicon {
	display: inline-block;
	background-size: 100%;
	width: 30px;
	height: 30px;
	text-indent: -999em;
	text-align: left;
	/*
	-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
	*/
	-moz-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25), inset -2px -2px 5px 0 rgba(0, 0, 0, 0.5), inset 2px 2px 5px 0 rgba(255, 255, 255, 1);
	-webkit-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25), inset -2px -2px 5px 0 rgba(0, 0, 0, 0.5), inset 2px 2px 5px 0 rgba(255, 255, 255, 1);
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25), inset -2px -2px 3px 0 rgba(0, 0, 0, 0.5), inset 2px 2px 3px 0 rgba(255, 255, 255, 1);
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 5px;
}
.webicon:hover {
	/*
	margin: -1px 0 1px;
	-moz-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
	*/
	-moz-box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.5), inset -2px -2px 5px 0 rgba(255, 255, 255, 1);
	-webkit-box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.5), inset -2px -2px 5px 0 rgba(255, 255, 255, 1);
	box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.5), inset -2px -2px 3px 0 rgba(255, 255, 255, 1);
}
.webicon.small {
	width: 20px;
	height: 20px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}
.webicon.large {
	width: 48px;
	height: 48px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
}

/* restore webicons accessibility for print */
@media print {
  * {
		background: transparent !important;
		color: #000 !important;
		-moz-box-shadow: none !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}
  .webicon {
		text-indent: 0;
		width: auto !important;
		height: auto !important;
		-moz-border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
		border-radius: 0 !important;
	}
}

// Include or remove the icons you want to use on your site from this list.
$webicons-icons: facebook;

// Specify the path to the webicons directory here. Include trailing slash.
$webicons-url: "webicons/" !default;

@each $webicons-icon in $webicons-icons {
  //.no-svg .webicon.#{$webicons-icon}       { background: url("#{$webicons-url}webicon-#{$webicons-icon}-m.png"); }
  //.no-svg .webicon.#{$webicons-icon}.large { background: url("#{$webicons-url}webicon-#{$webicons-icon}.png"); }
  //.no-svg .webicon.#{$webicons-icon}.small { background: url("#{$webicons-url}webicon-#{$webicons-icon}-s.png"); }
  .webicon.#{$webicons-icon}       { background: url("#{$webicons-url}webicon-#{$webicons-icon}.svg"); }
}
