// Override (inancientpaths)
// --------

#header {
	border-bottom: solid 1px #006B05;
}
.medium-align-bottom {
	@include breakpoint(medium) {
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
	}
}
#footer {
	padding-top: 0.5rem;
	border-top: solid 1px #006B05;
	background: #F4E9AF;
}

.checkbox {
	margin-bottom: 1rem;
	input {
		margin: 0;
	}
	label {
		display: inline;
		line-height: 1;
		margin-right: 0;
	}
}

p.close {
	margin-bottom: 0;
}
//
//  Table of Contents:
//
//	1. Body
//	2. Content
//		2.1 Buttons
//		2.? Callouts
//		2.3	Content Management page
//		2.3	Forms
//		2.5 full.width
//		2.6 Home page
//			2.6.1	bizazz
//			2.6.2	.middle
//			2.6.3	.wegrow, inc .wombat and .grass
//		2.7 Portfolio page
//		2.8 Tables
//		2.9 Text Styles
//	3. Fonts
//	4. Footer
//	5. Menus, inc sticky, top amd title bar
//
//	---- end table of contents ---

//	1. Body
// -----------------------------------------------------------------------1
.body {
	// font-family: "Helvetica Neue";
}
//.is-at-bottom {
//	top: 0 !important;
//}
.menu-icon {
	vertical-align: top;
}
.top-bar {
	flex-wrap: wrap;
}
.no-js .top-bar {
  display: none;
}
@media screen and (min-width: 40em) {
  .no-js .top-bar {
    display: block;
  }
  .no-js .title-bar {
    display: none;
  }
}
.menu-text {
	padding: 0;
	font-size: 150%;
	font-weight: normal;
	img {
		height: 3rem;
	}
}
.title-bar {
	.title-bar-title {
		a {
			color: $black;
		}
		img {
			height: 3rem;
		}
	}
	.menu-icon {
		margin-top: 0;
	}
}
.top-bar {
	&, & ul {
		background-color: $white;
		@media only screen and (max-width: 40em) {
			background-color: $light-gray;
		}
	}
	.menu-text a {
		padding: 0;
		color: $black;
	}
	img {
		height: 6rem;
	}
}

.menu {
	li {
		a {
			color: #720098;
			&:hover {
				color: scale-color(#720098, $lightness: 60%);
			}
		}
	}
}
.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
	border-color: #720098 transparent transparent;
	&:hover {
		border-color: scale-color(#720098, $lightness: 60%) transparent transparent;
	}
}
.is-drilldown-submenu-parent > a::after {
	border-color: transparent transparent transparent #720098;
	&:hover {
		border-color: transparent transparent transparent scale-color(#720098, $lightness: 60%);
	}
}
.js-drilldown-back > a::before {
	border-color: transparent #720098 transparent transparent;
}

//	2. Content
// -----------------------------------------------------------------------2

#content {
	h1 {
		color: #006B05;
	}
}

